<template>
  <div class="rounded-lg shadow-lg">
    <basic-information mode="create" :company="company" />
  </div>
</template>
<script>
import { BCol, BRow } from "bootstrap-vue";
import basicInformation from "./components/company-basic-information.vue";
export default {
  data() {
    return {
      company: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        name: "",
        info: {
          industry: "",
          abn: "",
          acn: "",
          tfn: "",
          city: "",
          state: "",
        },
      },
    };
  },
  components: {
    BRow,
    BCol,
    basicInformation,
  },
};
</script>
